const containerStyles = {
  position: 'fixed',
  bottom: '75px',
  right: '20px',
  width: '300px',
  backgroundColor: '#fcf6f6',
  border: '1px solid #dddddd',
  borderRadius: '8px',
  padding: '15px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: '1000',
  overflowY: 'auto',
  maxHeight: '400px',
  minHeight: '300px',
  display: 'none',
};

export function createContainer(): HTMLElement {
  let container = document.getElementById('profile-connect');
  if (!container) {
    container = document.createElement('div');
    container.id = 'profile-connect';

    Object.assign(container.style, containerStyles);
    document.body.appendChild(container);
  }

  return container;
}

export function toggleContainer(visible: boolean) {
  const container = document.getElementById('profile-connect');
  if (container) {
    container.style.display = visible ? 'block' : 'none';
  }
}
