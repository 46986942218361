const buttonStyles = {
  position: 'fixed',
  right: '20px',
  bottom: '20px',
  padding: '12px 24px',
  backgroundColor: '#2c3e50',
  color: '#fff',
  border: 'none',
  borderRadius: '30px',
  cursor: 'pointer',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  fontSize: '16px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease',
};

export function createFloatingButton(onClick: () => void): HTMLButtonElement {
  const button = document.createElement('button');
  button.id = 'profile-connect-button';
  button.innerText = 'Profile Connect';

  Object.assign(button.style, buttonStyles);

  button.onclick = onClick;

  return button;
}
