import { createFloatingButton } from './components/Button';
import { createContainer, toggleContainer } from './components/Container';
import { errorMessage } from './components/ErrorMessage';
import { renderSurveys } from './components/RenderSurveys';
import { getApiClient, initializeApiClient } from './services/apiBaseClient';
import type {
  ProfileConnectConfig,
  ProfileVariables,
  SurveyResponse,
} from './types';

const DEFAULT_EMBED_ID = 'profile-connect-section';

class ProfileConnect {
  private static containerVisible: boolean = false;
  private static cachedSurveyData: SurveyResponse[] | null = null;
  private static container: HTMLElement | null = null;

  static init(config: ProfileConnectConfig) {
    console.info('ProfileConnect initialized with config:', config);

    initializeApiClient(config.apiToken);

    if (config.displayMode === 'widget') {
      const button = createFloatingButton(() => this.handleButtonClick(config));
      document.body.appendChild(button);
    } else if (config.displayMode === 'embedded') {
      this.setupEmbeddedContainer(config);
    }
  }

  private static async handleButtonClick(config: ProfileConnectConfig) {
    this.containerVisible = !this.containerVisible;

    if (!this.containerVisible) {
      this.hideContainer();
      return;
    }

    this.showContainer();

    if (!this.cachedSurveyData) {
      await this.loadAndRenderSurveys(
        config.respondentId,
        config.profileVariables
      );
    } else {
      this.renderCachedSurveys(config.respondentId, config.profileVariables);
    }
  }

  private static setupEmbeddedContainer(config: ProfileConnectConfig) {
    const containerId = config.embedId || DEFAULT_EMBED_ID;
    const containerElement = document.getElementById(containerId);

    if (!containerElement) {
      console.error(`Container with ID "${containerId}" not found.`);
      return;
    }

    this.container = containerElement;
    this.loadAndRenderSurveys(config.respondentId, config.profileVariables);
  }

  private static showContainer() {
    if (!this.container) {
      this.container = createContainer();
    }
    toggleContainer(true);
  }

  private static hideContainer() {
    if (this.container) {
      toggleContainer(false);
    }
  }

  private static clearSurveys() {
    if (this.container) {
      this.container.innerHTML = '';
    }
  }

  private static async loadAndRenderSurveys(
    respondentId?: string,
    profileVariables?: ProfileVariables
  ) {
    this.clearSurveys()
    try {
      const apiClient = getApiClient();

      const data = await apiClient.get<
        { surveys: SurveyResponse[] },
        ProfileVariables
      >('/surveys', {
        query: { RespondentId: respondentId, ...profileVariables },
      });
      this.cachedSurveyData = data.surveys;
      renderSurveys(
        this.container!,
        data.surveys,
        respondentId,
        profileVariables
      );
    } catch (error) {
      errorMessage('Failed to load surveys. Please try again later.');
    }
  }

  private static renderCachedSurveys(
    respondentId: string,
    profileVariables?: ProfileVariables
  ) {
    if (this.container && this.cachedSurveyData) {
      renderSurveys(
        this.container,
        this.cachedSurveyData,
        respondentId,
        profileVariables
      );
    }
  }
}

export default ProfileConnect;
