export const sanitizeInput = (input: string): string => {
  return input
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#x27;")
    .replace(/\//g, "&#x2F;");
}

export const jsonToQueryString = (jsonObj: { [key: string]: any }): string => {
  const queryString = Object.keys(jsonObj)
    .map(key => {
      const value = jsonObj[key];
      if (Array.isArray(value)) {
        return value
          .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          .join("&");
      } if (typeof value === 'object' && value !== null) {
        return Object.keys(value)
          .map(innerKey =>
            `${encodeURIComponent(`${key}[${innerKey}]`)}=${encodeURIComponent(value[innerKey])}`)
          .join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryString;
}

