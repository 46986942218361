import { getApiClient } from '../services/apiBaseClient';
import { ProfileVariables, SurveyResponse } from '../types';
import { errorMessage } from './ErrorMessage';

const surveyItemStyles = {
  display: 'block',
  marginBottom: '8px',
  width: 'fit-content',
  cursor: 'pointer',
  backgroundColor: '#fff0f0',
  padding: '8px',
  borderRadius: '4px',
};

const onSurveyClick = async (
  surveyId: string,
  respondentId?: string,
  profileVariables?: ProfileVariables
) => {
  const api = getApiClient();
  try {
    const response = await api.post<{ redirectUrl: string }>('/surveys/start', {
      data: {
        SurveyId: surveyId,
        RespondentId: respondentId,
        ...profileVariables,
      },
    });
    if (response.redirectUrl) {
      const newTab = window.open(response.redirectUrl, '_blank');
      if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        window.location.href = response.redirectUrl;
      }
    }
  } catch (error) {
    errorMessage('Failed to start the survey. Please try again later.');
  }
};

export function renderSurveys(
  container: HTMLElement,
  surveys: SurveyResponse[],
  respondentId?: string,
  profileVariables?: ProfileVariables
) {
  container.innerHTML = '';
  container.className = 'survey-container';
  surveys.forEach(survey => {
    const surveyItem = document.createElement('div');
    surveyItem.className = 'survey-item';
    surveyItem.innerText = `${survey.surveyName} - (${survey.rewardPoints || 0} points)`;

    surveyItem.addEventListener('click', () =>
      onSurveyClick(survey.surveyId, respondentId, profileVariables)
    );

    Object.assign(surveyItem.style, surveyItemStyles);
    container.appendChild(surveyItem);
  });
}
