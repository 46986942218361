import { QueryParams, QueryType } from '../types';
import { jsonToQueryString } from '../utils';

interface RequestOptions<Q = QueryType> {
  method: string;
  url: string;
  data?: unknown;
  headers?: HeadersInit;
  query?: QueryParams<Q>;
}

export class ApiBaseClient {
  protected baseUrl: string;
  private defaultHeaders?: HeadersInit;

  constructor(baseUrl: string, options?: { headers?: HeadersInit }) {
    this.baseUrl = baseUrl;
    this.defaultHeaders = options?.headers;
  }

  protected async request<T, Q = QueryType>({
    method,
    url,
    data,
    headers,
    query,
  }: RequestOptions<Q>): Promise<T> {
    const combinedHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...this.defaultHeaders,
      ...headers,
    };

    let apiUrl = `${this.baseUrl}${url}`;

    if (query) {
      const queryString = jsonToQueryString(query);
      apiUrl += `?${queryString}`;
    }

    const requestOptions: RequestInit = {
      method,
      headers: combinedHeaders,
      body: data ? JSON.stringify(data) : null,
    };

    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      const error = await response.json();
      throw new Error(
        error?.message || `API request failed with status ${response.status}`
      );
    }

    return response.json();
  }

  public get<T, Q = QueryType>(
    url: string,
    options?: { headers?: HeadersInit; query?: QueryParams<Q> }
  ): Promise<T> {
    const { headers, query } = options ?? {};
    return this.request<T, Q>({ method: 'GET', url, headers, query });
  }

  public post<T, D = unknown, Q = QueryType>(
    url: string,
    options?: { headers?: HeadersInit; data?: D; query?: QueryParams<Q> }
  ): Promise<T> {
    const { data, headers, query } = options ?? {};
    return this.request<T, Q>({
      method: 'POST',
      url,
      data,
      headers,
      query,
    });
  }
}

let apiClient: ApiBaseClient | null = null;

export function initializeApiClient(apiToken: string) {
  const apiBaseUrl = process.env.API_BASE_URL;

  if (!apiBaseUrl) {
    console.error("Missing configs");
    return;
  }
  apiClient = new ApiBaseClient(apiBaseUrl, {
    headers: { 'supplier-access-token': apiToken },
  });
}

export function getApiClient(): ApiBaseClient {
  if (!apiClient) {
    throw new Error(
      'API client not initialized.'
    );
  }
  return apiClient;
}
