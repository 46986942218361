const closeBtnStyle = {
  top: '4px',
  right: '8px',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  color: '#f20524',
};

const errorElementStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#f20524',
  marginTop: '24px',
  backgroundColor: '#f5b5be',
  padding: '8px',
  fontSize: '12px',
  width: 'fit-content',
  borderRadius: '4px',
};

export const errorMessage = (message: string) => {
  const container = document.getElementsByClassName('survey-container')[0];

  const existingError = container?.getElementsByClassName('error-message')[0];
  if (existingError) {
    existingError.remove();
  }

  const errorElement = document.createElement('div');
  errorElement.className = 'error-message';
  errorElement.innerText = message;

  const closeButton = document.createElement('button');
  closeButton.innerText = 'X';

  Object.assign(errorElement.style, errorElementStyle);
  Object.assign(closeButton.style, closeBtnStyle);

  closeButton.addEventListener('click', () => {
    errorElement.remove();
  });

  errorElement.appendChild(closeButton);
  container?.appendChild(errorElement);
};
